import { createSlice } from '@reduxjs/toolkit';
var reviewStatsSlice = createSlice({
    name: 'reviewStats',
    initialState: {
        data: null,
        loading: false,
        error: null,
    },
    reducers: {
        fetchReviewStatsRequest: function (state, action) {
            state.loading = true;
            state.error = null;
        },
        fetchReviewStatsSuccess: function (state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        fetchReviewStatsFailure: function (state, action) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});
export var reviewStatsActions = reviewStatsSlice.actions;
export default reviewStatsSlice.reducer;
