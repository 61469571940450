import gte from 'lodash/gte';
import gt from 'lodash/gte';
import get from 'lodash/get';
import lt from 'lodash/lt';
import lte from 'lodash/lte';
import eq from 'lodash/eq';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import set from 'lodash/set';
import isMatch from 'lodash/isMatch';
import { produce } from 'immer';
import { AppointmentBookingSelectors, CommonSelectors, ConfigSelectors, IframeSelectors, JourneysSelectors, ProcessesSelectors, ResultsSelectors } from 'conversional-journey';
import { captureException } from 'conversional-exceptions';
var getCompareValueBasedOnSource = function (rule, operatorIndex, localData) {
    if (operatorIndex === void 0) { operatorIndex = 1; }
    if (localData === void 0) { localData = {}; }
    var source = rule["o".concat(operatorIndex, "Source")];
    if (!source) {
        return null;
    }
    var storeState = window.reduxStore.getState();
    var initialSource = Array.isArray(source) ? source[0] : source.split('.')[0];
    var extra = Array.isArray(source) ? source.slice(1) : source.split("".concat(initialSource, "."))[1];
    switch (initialSource) {
        case 'local': {
            return get(localData, extra);
        }
        case 'journey': {
            var journey = JourneysSelectors.currentJourney(storeState);
            return get(journey, extra);
        }
        case 'answers': {
            var process = ProcessesSelectors.getCurrentProcess(storeState);
            return get(process, "data.Answers.".concat(extra));
        }
        case 'processes': {
            var process = ProcessesSelectors.getCurrentProcess(storeState);
            return get(process, extra);
        }
        case 'results': {
            var result = ResultsSelectors.selectResultsState(storeState);
            return get(result, extra);
        }
        case 'config': {
            var config = ConfigSelectors.selectConfigState(storeState);
            return get(config, extra);
        }
        case 'external': {
            var externalData = ConfigSelectors.selectExternalData(storeState);
            return get(externalData, extra);
        }
        case 'iframe': {
            var externalData = IframeSelectors.selectIframeState(storeState);
            return get(externalData, extra);
        }
        case 'common': {
            var commonState = CommonSelectors.selectCommonState(storeState);
            return get(commonState, extra);
        }
        case 'appointmentBooking': {
            var appointmentBookingData = AppointmentBookingSelectors.selectAppointmentBooking(storeState);
            return get(appointmentBookingData, extra);
        }
    }
    return null;
};
var getCompareValue = function (rule, operatorIndex, localData) {
    if (operatorIndex === void 0) { operatorIndex = 1; }
    var rawKey = "o".concat(operatorIndex);
    // eslint-disable-next-line no-prototype-builtins
    return rule.hasOwnProperty(rawKey)
        ? rule[rawKey]
        : getCompareValueBasedOnSource(rule, operatorIndex, localData);
};
var checkEqual = function (o1, o2) {
    if (isArray(o1) && !isArray(o2))
        o1 = o1[0];
    else if (isArray(o2) && !isArray(o1))
        o2 = o2[0];
    return eq(o1, o2);
};
var processEachRule = function (rule, localData) {
    // Check rule validity for debugging
    if (!Object.prototype.hasOwnProperty.call(rule, 'o1') && !Object.prototype.hasOwnProperty.call(rule, 'o1Source') && !Object.prototype.hasOwnProperty.call(rule, 'o2') && !Object.prototype.hasOwnProperty.call(rule, 'o2Source')) {
        captureException(new Error("Rule ".concat(JSON.stringify(rule), " does not contain the required keys of the IRule type")));
        return true;
    }
    var o1 = getCompareValue(rule, 1, localData);
    var o2 = getCompareValue(rule, 2, localData);
    // Dangerous to use! Implemented as a draft of idea
    if (rule.comparator) {
        var comparatorFunction = eval(rule.comparator);
        if (typeof comparatorFunction !== 'function')
            return false;
        return comparatorFunction(o1, o2);
    }
    else {
        var operator = rule.operator || 'eq';
        switch (operator) {
            case 'booleanTrue':
                return (Boolean(o1) == true);
            case 'gt':
                return gt(o1, o2);
            case 'gte':
                return gte(o1, o2);
            case 'lt':
                return lt(o1, o2);
            case 'lte':
                return lte(o1, o2);
            case 'eq':
                return checkEqual(o1, o2);
            case 'includes':
                return includes(o1, o2);
            case 'empty':
                return isEmpty(o1);
            case 'isMatch':
                return isMatch(o1, o2);
            case 'undefined':
                return o1 === undefined;
        }
    }
    return false;
};
var processRuleResult = function (rule, localData) {
    var result = processEachRule(rule, localData);
    return rule.not ? !result : result;
};
export var processConditional = function (conditional, results, condition, localData) {
    if (results === void 0) { results = []; }
    if (!condition)
        condition = conditional.condition || 'AND';
    if (isEmpty(results))
        results = [];
    for (var _i = 0, _a = conditional.rules; _i < _a.length; _i++) {
        var ruleOrCondition = _a[_i];
        if ('condition' in ruleOrCondition) {
            results.push(processConditional(ruleOrCondition, [], ruleOrCondition.condition, localData));
        }
        else {
            results.push(processRuleResult(ruleOrCondition, localData));
        }
    }
    return results[condition === 'AND' ? 'every' : 'some'](function (result) { return result === true; });
};
export var processArrayForConditional = function (array, showKey) {
    if (showKey === void 0) { showKey = 'show'; }
    return (array || []).filter(function (element) {
        if (typeof get(element, showKey) !== "object")
            return true;
        return processConditional(get(element, "".concat(showKey, ".conditional")));
    });
};
export var processArrayForConditionalValue = function (array, valueKey) {
    if (valueKey === void 0) { valueKey = 'disabled'; }
    var updatedArray = produce(array || [], function (draft) {
        draft.forEach(function (element) {
            if (typeof get(element, valueKey) === "object") {
                var conditionalValue = get(element, "".concat(valueKey, ".conditional"));
                element[valueKey]["value"] = processConditional(conditionalValue);
            }
        });
    });
    return updatedArray;
};
export var processConditionalProps = function (props, conditionalProps, isDirectComponent) {
    if (isDirectComponent === void 0) { isDirectComponent = false; }
    if (!Array.isArray(get(conditionalProps, 'conditions')))
        return {};
    var isContextComponent = function () { return get(props, 'children.props.contextId'); };
    var getPropPath = function (path) { return (isDirectComponent ? "".concat(isContextComponent() ? 'contextProps.' : '').concat(path)
        : "children.props.".concat(isContextComponent() ? 'contextProps.' : '').concat(path)); };
    return produce(props, function (draft) {
        conditionalProps.conditions.forEach(function (condition) {
            if (processConditional(condition)) {
                if (Array.isArray(condition.values)) {
                    condition.values.forEach(function (conditionValue) { return set(draft, getPropPath(conditionValue.propPath), conditionValue.value); });
                }
                else {
                    var propPath = getPropPath(condition.propPath);
                    set(draft, propPath, condition.value);
                }
            }
        });
    });
};
