import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
export var selectConfigState = function (state) { return state.config; };
export var selectConfigAPIKey = createSelector(selectConfigState, function (config) { return config.apiKey; });
export var selectConfigBaseURL = createSelector(selectConfigState, function (config) { return config.baseUrl; });
export var selectConfigVersion = createSelector(selectConfigState, function (config) { return config.version; });
export var selectExternalData = createSelector(selectConfigState, function (config) { return get(config, 'external.data'); });
export var selectQueryParams = createSelector(selectConfigState, function (config) { return get(config, 'external.hostQueryParams', {}); });
export var selectExternalProcess = createSelector(selectConfigState, function (config) { return get(config, 'external.process'); });
export var selectProcessPathNameInExternal = createSelector(selectConfigState, function (config) { return get(config, 'external.selectedProcessPathName'); });
export var selectHostQueryParams = createSelector(selectConfigState, function (config) { return get(config, 'external.hostQueryParams', {}); });
export var selectHostReferrer = createSelector(selectConfigState, function (config) { return get(config, 'external.hostReferrer'); });
export var abTestSelector = function (state) {
    return state.config.abTest || undefined;
};
export var initialJourneyId = function (state) {
    return state.config.journeyId;
};
