var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineReducers } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';
import { mergeState } from './config/configSlice';
import { clearState } from './common/commonSlice';
import commonReducers from './common/commonSlice';
import configReducers from './config/configSlice';
import iframeReducers from './iframe/iframeSlice';
import journeysReducers from './journeys/journeysSlice';
import processesReducers from './processes/processesSlice';
import resultsReducers from './results/resultsSlice';
import timeslotsReducers from './timeslots/timeslotsSlice';
import reviewsReducers from './reviews/reviewStatsSlice';
import appointmentBookingReducers from './appointmentBooking/appointmentBookingSlice';
import { rtkApi } from '../api/rtkApi';
import locationsReducers from './locations/locationsSlice';
import trackingReducers from './tracking/trackingSlice';
var CLEAR_STATE = {
    config: {},
    common: {},
    journeys: {
        journeys: [],
        journeyId: null,
    },
    locations: {
        locations: [],
    },
    processes: {
        processes: [],
    },
    results: {
        results: [],
        resultId: null,
        formResult: {},
        calendarLinks: {}
    },
    reviewsReducers: {},
    timeslots: {
        timeslots: [],
    },
    api: {}
};
var createReducers = function (extraReducers) {
    var _a;
    if (extraReducers === void 0) { extraReducers = {}; }
    var reducer = combineReducers(__assign((_a = { config: configReducers, iframe: iframeReducers, journeys: journeysReducers, locations: locationsReducers, common: commonReducers, processes: processesReducers, timeslots: timeslotsReducers, appointmentBooking: appointmentBookingReducers, results: resultsReducers, tracking: trackingReducers, reviewStats: reviewsReducers }, _a[rtkApi.reducerPath] = rtkApi.reducer, _a), extraReducers));
    var rootReducer = function (state, action) {
        if (state === void 0) { state = CLEAR_STATE; }
        if (action.type === clearState.type) {
            var removedConfigOnReset = ['token'];
            var keepState = {
                api: state.api,
                journeys: state.journeys,
                iframe: state.iframe,
                config: omit(state.config, removedConfigOnReset),
            };
            if (state.journeys) {
                state = __assign(__assign({}, CLEAR_STATE), keepState);
            }
            else {
                state = __assign({}, keepState);
            }
        }
        var newState = cloneDeep(state);
        if (action.type === mergeState.type) {
            newState = merge(newState, action.data);
        }
        newState = reducer(newState, action);
        newState.common = __assign(__assign({}, (newState.common || {})), { updatedAt: new Date().getTime() });
        return newState;
    };
    return rootReducer;
};
export default createReducers;
