import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import { captureException } from "conversional-exceptions";
function filterProcess(processes, processId) {
    return processes.filter(function (item) { return item.id === processId; })[0] || null;
}
var selectors = [function (state) { return state.processes.processes; }, function (state, processId) { return processId; }];
export var getProcess = createSelector(selectors, function (processes, processId) {
    return filterProcess(processes, processId);
});
export function getCurrentProcess(state) {
    return state.processes.processes[0] || null;
}
export function selectProcesses(state) {
    return state.processes;
}
export var selectCurrentProcess = createSelector(getCurrentProcess, function (process) { return process; });
export var selectProcessAnswers = createSelector(selectCurrentProcess, function (process) { return get(process, 'data.Answers'); });
export var selectCurrentProcessStep = createSelector(selectCurrentProcess, function (process) { return get(process, 'step'); });
export var selectCurrentProcessData = createSelector(selectCurrentProcess, function (process) { return get(process, 'data'); });
export var getProcessDataById = createSelector(selectors, function (processes, processId) {
    return get(filterProcess(processes, processId), 'data');
});
// Added this temporary function in Sep 2024 for the incomplete answers to use the single process data to check if the selector was the problem
export var getProcessDataById_withDefaultFirstProcessData = createSelector(selectors, function (processes, processId) {
    var processDataById = get(filterProcess(processes, processId), 'data', {});
    if (processDataById.Customer && processDataById.Answers)
        return processDataById;
    var defaultProcessData = get(processes, '0.data');
    if (!defaultProcessData) {
        captureException("Empty process data", {
            processes: processes
        });
        return {};
    }
    return defaultProcessData;
});
export var getProcessAfterCompletedById = createSelector(selectors, function (processes, processId) {
    return get(filterProcess(processes, processId), 'afterCompleted');
});
export var getProcessPathById = createSelector(selectors, function (processes, processId) {
    return get(filterProcess(processes, processId), 'path');
});
export var getProcessStepById = createSelector(selectors, function (processes, processId) {
    return get(filterProcess(processes, processId), 'step', 1);
});
export var getProcessPathNameById = createSelector(selectors, function (processes, processId) {
    return get(filterProcess(processes, processId), 'pathName');
});
export var getCurrentProcessServiceRequestAppointment = createSelector(getCurrentProcess, function (process) { return get(process, 'data.Customer.ServiceRequest.appointment'); });
