var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
import merge from "lodash/merge";
import cloneDeep from "lodash/cloneDeep";
import { dateAndTimeHelpers, JourneysSelectors } from 'conversional-journey';
import { createTheme } from 'conversional-theme';
import { initialJourneyId } from '../../config/selectors';
export var currentJourneyId = function (state) {
    var jidFromConfig = initialJourneyId(state);
    return state.journeys.journeyId || jidFromConfig;
};
export var getJourneyById = function (state, journeyId) {
    getJourney(state.journeys.journeys, journeyId);
};
export function getJourney(journeys, journeyId) {
    var journey = journeys.filter(function (journey) { return journey.id === journeyId; });
    var picked = journey[0] || null;
    return picked;
}
export var currentJourney = function (state) {
    return getJourney(state.journeys.journeys, currentJourneyId(state));
};
export var selectJourneys = function (state) {
    return state.journeys.journeys;
};
// Sub-journey selectros
// Theme
export var selectTheme = createSelector(currentJourney, function (journey) { return (journey && journey.theme) || createTheme({}); });
// Locale
export var selectTranslations = createSelector(currentJourney, function (journey) { var _a; return ((_a = journey === null || journey === void 0 ? void 0 : journey.locale) === null || _a === void 0 ? void 0 : _a.translations) || {}; });
export var selectTranslationKeys = createSelector([
    selectTranslations,
    function (state, translationKeys) { return typeof translationKeys === "string" ? [translationKeys] : translationKeys; }
], function (translations, translationKeys) { return translationKeys.reduce(function (acc, key) {
    var _a;
    return (__assign(__assign({}, acc), (_a = {}, _a[key] = get(translations, key), _a)));
}, {}); });
export var selectTranslationKeysAsAnArray = createSelector([
    selectTranslations,
    function (state, translationKeys) { return typeof translationKeys === "string" ? [translationKeys] : translationKeys; }
], function (translations, translationKeys) { return translationKeys.map(function (key) { return get(translations, key); }).filter(Boolean); });
export var selectTranslationKey = createSelector([
    selectTranslations,
    function (state, translationKey) { return translationKey; }
], function (translations, translationKey) { return get(translations, translationKey); });
export var DEFAULT_LOCALE = {
    lang: 'de',
    timezone: 'Europe/Berlin',
};
// Locale
export var selectLocale = createSelector(currentJourney, function (journey) {
    if (!journey || !journey.locale) {
        return DEFAULT_LOCALE;
    }
    // check entered locale schema
    if (!journey.locale.lang || !journey.locale.timezone) {
        return __assign(__assign({}, DEFAULT_LOCALE), journey.locale);
    }
    var checkTimezoneValidity = dateAndTimeHelpers.isValidTimeZone(journey.locale.timezone);
    if (checkTimezoneValidity === true) {
        return journey.locale;
    }
    // returns error
    return checkTimezoneValidity;
});
var DEFAULT_GEO_LOCATION_ERRORS = {
// [browserUtil.BROWSER_GEO_LOCATION_POSITION_ERRORS.POSITION_UNAVAILABLE]: "Cannot get position from your browser",
// [browserUtil.BROWSER_GEO_LOCATION_POSITION_ERRORS.PERMISSION_DENIED]: "Permission for getting location info is denied. Please review your browser's settings.",
// [browserUtil.BROWSER_GEO_LOCATION_POSITION_ERRORS.NOT_SUPPORTED]: "Cannot get position from your browser",
// [browserUtil.BROWSER_GEO_LOCATION_POSITION_ERRORS.TIMEOUT]: "Cannot get position from your browser",
};
export var selectGeoLocationErrors = function (state) { return merge(DEFAULT_GEO_LOCATION_ERRORS, cloneDeep(get(JourneysSelectors.selectLocale(state), 'components.geoLocation.errors', {}))); };
var DEFAULT_LOCALE_STRINGS = { at: "", oclock: "" };
export var selectLocaleStrings = createSelector(currentJourney, function (journey) {
    if (!journey || !journey.locale) {
        return DEFAULT_LOCALE_STRINGS;
    }
    return journey.locale.strings || DEFAULT_LOCALE_STRINGS;
});
// Call to actions
export var selectCallToAction = createSelector(currentJourney, function (journey) { return journey.callToAction || {}; });
export var selectLauncher = createSelector(selectCallToAction, function (journey) { return journey.loader || {}; });
export var selectPreloadingEnabled = createSelector(currentJourney, function (journey) { return journey.preloading !== false; });
// This is not being used anywhere so commenting out for now
// export const selectLauncherActions = createSelector(
//   selectLauncher,
//   (launcher) => (launcher || {}).actions || []
// )
export var selectExitIntent = createSelector(currentJourney, function (journey) { return journey.callToAction.exitIntent || null; });
export var selectExitIntentMobile = createSelector(currentJourney, function (journey) { return journey.callToAction.exitIntentMobile; });
export var selectInternalExitIntents = createSelector(currentJourney, function (journey) { return journey.internalExitIntents || {}; });
export var selectKitchenAnimation = createSelector(currentJourney, function (journey) {
    if (!journey || !journey.entry.root) {
        return {};
    }
    var useKitchenAnimation = get(journey.entry.root, 'Content.props.useKitchenAnimation', false);
    return useKitchenAnimation;
});
export var selectJourneyBookingObject = createSelector(currentJourney, function (journey) { return journey.booking || {}; });
