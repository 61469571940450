import isEmpty from "lodash/isEmpty";
var AT = 'um';
var HOURS = 'Uhr';
var localeMap = {
    en: 'en',
    fr: 'fr',
    nl: 'nl',
    de: 'de',
    es: 'es',
};
function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== 'Invalid Date';
}
export var formatDate = function (dateObj, locale, timeOptions, extraOptions) {
    if (timeOptions === void 0) { timeOptions = {}; }
    if (extraOptions === void 0) { extraOptions = {}; }
    if (!isValidDate(dateObj))
        return '';
    var ye = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(dateObj);
    var mo = new Intl.DateTimeFormat(locale, { month: extraOptions.monthFormat || 'long' }).format(dateObj);
    var moNum = new Intl.DateTimeFormat(locale, { month: 'numeric' }).format(dateObj);
    var da = new Intl.DateTimeFormat(locale, { day: '2-digit' }).format(dateObj);
    var daName = new Intl.DateTimeFormat(locale, {
        weekday: extraOptions.weekdayFormat || 'long',
    }).format(dateObj);
    if (extraOptions.short) {
        return "".concat(da, "-").concat(moNum, "-").concat(ye);
    }
    if (extraOptions.justMonth) {
        return "".concat(mo).toUpperCase();
    }
    if (extraOptions.weekdayAndDate) {
        return "".concat(daName, ", ").concat(da, ".").concat(moNum, ".").concat(ye);
    }
    if (isEmpty(timeOptions)) {
        if (locale === localeMap.fr) {
            return "".concat(daName, ", ").concat(da, " ").concat(mo, " ").concat(ye);
        }
        return "".concat(daName, ", ").concat(da, ". ").concat(mo, " ").concat(ye);
    }
    if (typeof timeOptions === "boolean" && timeOptions === true) {
        timeOptions = {};
    }
    var labelAt = timeOptions.labelAt, labelHours = timeOptions.labelHours, timeZone = timeOptions.timeZone, justTime = timeOptions.justTime;
    var time = formatTime(dateObj, locale, timeZone, labelHours || (justTime ? "" : HOURS));
    if (justTime) {
        return time;
    }
    if (locale === localeMap.fr) {
        return "".concat(daName, ", ").concat(da, " ").concat(mo, " ").concat(ye, " ").concat(labelAt || AT, " ").concat(time.replace(':', ' h '));
    }
    return "".concat(daName, ", ").concat(da, ". ").concat(mo, " ").concat(ye, " ").concat(labelAt || AT, " ").concat(time);
};
export function isValidTimeZone(tz) {
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
        return {
            error: 'Time zones are not available in this environment',
            lang: undefined,
            timezone: undefined
        };
    }
    try {
        Intl.DateTimeFormat(undefined, { timeZone: tz });
        return true;
    }
    catch (ex) {
        return {
            error: 'Invalid timezone entered',
            lang: undefined,
            timezone: undefined
        };
    }
}
export var formatTime = function (dateObj, locale, timeZone, labelHours) {
    if (labelHours === void 0) { labelHours = ''; }
    var formatOptions = {
        hour: 'numeric',
        minute: 'numeric',
    };
    if (timeZone) {
        formatOptions.timeZone = timeZone;
    }
    return new Intl.DateTimeFormat(locale, formatOptions).format(dateObj) + (labelHours ? " ".concat(labelHours) : '');
};
var isSaturdayOrSunday = function (dateString) {
    var date = new Date(dateString);
    return [0, 6].includes(date.getDay());
};
var isSameDay = function (date1, date2) {
    return date1.split('T')[0] === date2.split('T')[0];
};
var addDaysToDate = function (dateString, days) {
    var date = new Date(dateString);
    date.setDate(date.getDate() + days);
    return date.toISOString();
};
var isSameDayOrNextSunday = function (currentDate, firstDate) {
    var isTomorrow = isSameDay(addDaysToDate(firstDate, 1), currentDate);
    return isSameDay(currentDate, firstDate) || (isTomorrow && isSaturdayOrSunday(currentDate));
};
export var omitIfFirstDateOptionIsSaturdayOrSunday = function (list) {
    if (!Array.isArray(list))
        return [];
    var firstDate = list[0];
    if (!isSaturdayOrSunday(firstDate.start))
        return list;
    var foundSameDayIndex = 0;
    for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
        var dateItem = list_1[_i];
        if (isSameDayOrNextSunday(dateItem.start, firstDate.start)) {
            foundSameDayIndex++;
        }
        else
            break;
    }
    list.splice(0, foundSameDayIndex);
    return list;
};
